// src/extension/extension.js
import React from 'react';
import YKYRLogoType from '../assets/YKYRLogoType.tsx';

const Extension = () => {
  return (
    <div className="min-h-screen bg-[#f9ecd6] text-[#856421] py-8 px-4">
      <div className="max-w-[780px] mx-auto mb-6 flex justify-center">
        <YKYRLogoType />
      </div>

      <div className="max-w-[780px] mx-auto border-4 border-[#856421] p-8">
        <div className="space-y-6 text-base">
          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-4">1. Go to Chrome Web Store</h2>
            <p className="leading-relaxed mb-4">
              Visit our extension page on the Chrome Web Store
            </p>
            <a
              href="https://chromewebstore.google.com/detail/ykyr-preview/gfogbpcdlcihfaejpmdhigejhpckdamh"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#405783] text-[#f9ecd6] px-6 py-3 rounded-lg inline-block hover:opacity-90 transition-opacity"
            >
              Open Chrome Web Store
            </a>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-4">2. Click "Add to Chrome"</h2>
            <p className="leading-relaxed mb-4">
              Look for the blue "Add to Chrome" button in the Chrome Web Store and click it
            </p>
            <img
              src="/download/Add-to-Chrome-pointer.png"
              alt="Add to Chrome"
              className="rounded-lg shadow-sm"
            />
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold mb-4">3. Installation Complete</h2>
            <p className="leading-relaxed">
              YKYR extension is now installed! Create an account and start getting rewards!
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Extension;

