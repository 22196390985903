// src/legal/terms.js
import React from 'react';
import LegalComponent from './components/LegalComponent';


const Terms = () => {
  return (
    <LegalComponent title="Terms and Conditions">
      <section>
        <h2 className="text-xl font-bold mb-4">1. Introduction</h2>
        <p className="leading-relaxed">
        Welcome to YKYR. By using the YKYR browser extension, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">2. User Agreement</h2>
        <p className="leading-relaxed">
        By turning on the YKYR browser extension, you agree to the collection of your browsing data (including URL links, time spent) for the purpose of selling this data to interested third parties. This agreement also means that you accept the possibility of receiving rewards in exchange for contributing your data.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">3. Data Collection and Sale</h2>
        <p className="leading-relaxed">
        When you choose to enable the extension, you consent to the collection and registration of your browsing data on the blockchain. This data is verified by Vana's TEE validator to provide proof of contribution. Once registered, this data may be sold to third parties who pay for access, and you will be rewarded accordingly.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">4. User Control and Opt-Out</h2>
        <p className="leading-relaxed">
        You have the right to turn off the YKYR extension at any time. Turning off the extension will stop the collection of your browsing data and will also mean that you are no longer eligible to receive rewards from the YKYR program.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">5. Rewards</h2>
        <p className="leading-relaxed">
        Users who agree to participate in data collection will receive rewards based on the data they contribute. The value and nature of these rewards may vary depending on the third-party buyers' requirements and the value of the data collected.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">6. Data Security</h2>
        <p className="leading-relaxed">
        All data collected is encrypted locally within your extension, and you hold the encryption key. YKYR does not have access or control of your data. Only buyers who purchase the data receive the decryption key to access it. Therefore, YKYR is not liable for any risks related to data leakage.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">7. Liability</h2>
        <p className="leading-relaxed">
        YKYR is not responsible for any issues related to data privacy or leakage, as we do not hold any decryption keys. Full control of the data remains with you, and access is only granted to buyers upon purchase.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">8. Compliance</h2>
        <p className="leading-relaxed">
        YKYR complies with GDPR and CCPA regulations. You have the right to access, delete, or manage the data collected. We are committed to transparency, user consent, and allowing users to manage their data.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">9. Modifications to Terms</h2>
        <p className="leading-relaxed">
        YKYR may modify these Terms and Conditions from time to time. We will notify you of any changes, and your continued use of the extension implies your acceptance of the updated terms.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">10. Termination</h2>
        <p className="leading-relaxed">
        As YKYR is a decentralized protocol, development and support of certain features may cease if necessary. Users will retain access to the protocol, and can continue using the decentralized features, as the protocol itself cannot restrict user access. Users may also choose to stop using the extension at any time.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">11. Contact Information</h2>
        <p className="leading-relaxed">
        If you have any questions about these Terms and Conditions, please contact us at https://x.com/ykyrnow
        </p>
      </section>

    </LegalComponent>
  );
};

export default Terms;