// src/legal/privacy.js
import React from 'react';
import LegalComponent from './components/LegalComponent';


const Privacy = () => {
  return (
    <LegalComponent title="Privacy Policy">
      <section>
        <h2 className="text-xl font-bold mb-4">1. Introduction</h2>
        <p className="leading-relaxed">
        YKYR respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your data through the YKYR browser extension and services.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">2. Data Collection</h2>
        <p className="leading-relaxed">
        The YKYR extension collects browsing data, including URL links, time spent on each page. This data is collected only with your explicit consent and is used to enable you to monetize your browsing activity while maintaining full control over your data.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">3. Data Security</h2>
        <p className="leading-relaxed">
        All data collected is encrypted locally within your extension, and only you hold the encryption key. YKYR does not have access to your data. When buyers purchase the data, they receive a decryption key that allows them to access the relevant information. This ensures that YKYR cannot view or misuse your data.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">4. Data Sharing</h2>
        <p className="leading-relaxed">
        Your data is only shared when you decide to sell it. Buyers who purchase your data will receive a decryption key to access the purchased data. YKYR facilitates the transaction but is not able to access the data.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">5. User Rights</h2>
        <p className="leading-relaxed">
        You have full control over your data. You may delete, access, or manage the data collected through the YKYR extension at any time. You can also decide whether to collect and sell your data or opt out of data collection entirely.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">6. Liability</h2>
        <p className="leading-relaxed">
        YKYR is not responsible for any data leakage risks, as we do not hold the encryption key to your data. Only you and the buyer have access to the decryption keys, making data control entirely user-centric.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">7. Compliance with GDPR and CCPA</h2>
        <p className="leading-relaxed">
        YKYR complies with GDPR and CCPA regulations. You have the right to know what data is collected, request the deletion of your data, and opt out of data sales. We ensure transparency and give users full control over their personal information.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">8. Changes to This Policy</h2>
        <p className="leading-relaxed">
        YKYR may update this Privacy Policy from time to time. You will be notified of any changes, and continued use of the YKYR extension after changes take effect means that you accept the new terms.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-bold mb-4">9. Contact Information</h2>
        <p className="leading-relaxed">
        If you have questions or concerns about this Privacy Policy or your data rights, please contact us at https://x.com/ykyrnow
        </p>
      </section>

    </LegalComponent>
  );
};

export default Privacy;