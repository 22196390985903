// src/legal/components/LegalComponent.js
import React from 'react';
import YKYRLogoType from '../../assets/YKYRLogoType.tsx';
import '../styles/legal.css';

const LegalComponent = ({ title, children }) => {
  return (
    <div className="min-h-screen bg-[#f9ecd6] text-[#856421] py-8 px-4">
      <div className="max-w-[780px] mx-auto mb-6 flex justify-center">
        <YKYRLogoType />
      </div>
      
      {/* メインコンテンツ */}
      <div className="max-w-[780px] mx-auto border-4 border-[#856421] p-8">
        <h1 className="text-3xl font-bold mb-6">{title}</h1>
        <div className="space-y-6 text-base">
          {children}
        </div>
      </div>
    </div>
  );
};
export default LegalComponent;