import React, { useState, useMemo, useRef } from 'react';

const RTL_LANGUAGES = ['ar', 'he', 'fa', 'ur']; // RTL support

const LanguageSelector = ({ translations, onLanguageChange, selectedLanguage }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const languages = useMemo(() => {
    return Object.entries(translations).map(([key, value]) => ({
      key,
      ...value
    }));
  }, [translations]);

  const filteredLanguages = useMemo(() => {
    return languages.filter(lang => 
      lang.English_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lang.Native_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lang.alpha_2.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [languages, searchQuery]);

  const isRTL = selectedLanguage && RTL_LANGUAGES.includes(selectedLanguage.alpha_2);

  const handleSelectChange = (e) => {
    onLanguageChange(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowDropdown(true);
  };

  const handleLanguageClick = (langKey) => {
    onLanguageChange(langKey);
    setSearchQuery('');
    setShowDropdown(false);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputBlur = (e) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setShowDropdown(false);
    }
  };

  // selectedLanguageのkeyを取得する関数
  const getSelectedLanguageKey = () => {
    return selectedLanguage ? Object.keys(translations).find(key => translations[key] === selectedLanguage) : '';
  };

  return (
    <div className={`language-selector ${isRTL ? 'rtl-support' : ''}`}>
      <div className="selector-container">
        <select 
          value={getSelectedLanguageKey()}
          onChange={handleSelectChange}
        >
          {languages.map((lang) => (
            <option key={lang.key} value={lang.key}>
              {lang.Native_name}
            </option>
          ))}
        </select>
        <div className="search-container">
          <input
            type="text"
            placeholder={isRTL ? "اللغات بحث" : "Search languages"}
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {showDropdown && (
            <ul ref={dropdownRef} className="language-dropdown">
              {filteredLanguages.map((lang) => (
                <li 
                  key={lang.key} 
                  onClick={() => handleLanguageClick(lang.key)}
                  tabIndex="0"
                  role="option"
                  aria-selected={selectedLanguage && selectedLanguage.alpha_2 === lang.alpha_2}
                >
                  {lang.Native_name} ({lang.English_name})
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;