import React, { useState, useEffect } from 'react';
import LanguageSelector from './components/LanguageSelector';
import MainContent from './components/MainContent';

const App = () => {
  const [translations, setTranslations] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await fetch('/translations.json');
        const data = await response.json();
        setTranslations(data);
        
        const defaultLang = Object.values(data).find(lang => lang.alpha_2 === 'en') || Object.values(data)[0];
        setSelectedLanguage(defaultLang);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching translations:', error);
        setIsLoading(false);
      }
    };

    fetchTranslations();
  }, []);

  const handleLanguageChange = (langKey) => {
    setSelectedLanguage(translations[langKey]);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <LanguageSelector 
        translations={translations} 
        onLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
      />
      <MainContent selectedLanguage={selectedLanguage} />
    </div>
  );
};

export default App;